import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { List } from './List/index';
import { Divider } from 'antd';
import { getValueByCode } from '../../../shared/utils/getValueByCode';
import { APP_CONSTANS } from '../../../shared/constants';
import styles from './styles.module.scss';
import clsx from 'clsx';
import theme from '../../../theme/index';
import iconAmateur from '../../../shared/images/star.svg';
import iconEnthusiast from '../../../shared/images/trophy.svg';
import iconProffesional from '../../../shared/images/crown.svg';
import iconElite from '../../../shared/images/elite.svg';
import customStyle from '../../../shared/styles/custom.module.scss';

export const Navigation = ({
  showOnboardingButton,
  setShowOnboarding,
  openedMenu,
  profileImage,
  setOpenedMenu,
  name,
}) => {
  const currentUser = useSelector((state) => state.user.user);
  const currentUserPrefs = useSelector((state) => state.user.userPrefs);
  const [userExperience, setUserExperience] = useState('');
  const [showListForSuperuser, setShowListForSuperuser] = useState(false);
  const nav = useRef(null);
  const CURRENT_YEAR = new Date().getFullYear();

  const { AMATEUR, ENTHUSIAST, PROFESSIONAL, ELITE } = APP_CONSTANS.PHOTOGRAPHER_PROPS.LEVEL;

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const closeMenu = useCallback(
    (event) => {
      const target = event.target;
      const navMenu = nav.current;
      const profileImg = profileImage.current;
      if (navMenu) {
        let itsMenu = target === navMenu || navMenu.contains(target);
        let itsProfileImg =
          target === profileImg || profileImg.contains(target);
        const isDesktopView = getWindowDimensions();
        if (isDesktopView.width > 1370 && !itsMenu && !itsProfileImg) {
          setOpenedMenu(false);
        }
      }
    },
    [profileImage, setOpenedMenu],
  );

  useEffect(() => {
    window.addEventListener('click', closeMenu);
    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, [closeMenu]);

  useEffect(() => {
    if (currentUserPrefs && currentUserPrefs.length > 0) {
      const levels = currentUserPrefs.find(
        (i) => i.preference_id === APP_CONSTANS.PHOTOGRAPHER_PROPS.LEVEL.CODE,
      );
      const filteredLevel = levels.preference.options.find(
        (level) => level.value === levels.value,
      );
      setUserExperience(
        filteredLevel?.value
          ? filteredLevel.value
          : APP_CONSTANS.PHOTOGRAPHER_PROPS.LEVEL.AMATEUR,
      );
    }
  }, [currentUserPrefs]);

  useEffect(() => {
    if (Object.entries(currentUser).length > 0) {
      if (currentUser.type === 'superuser') {
        setShowListForSuperuser(true);
      }
    }
  }, [currentUser]);

  const getIconForLevel = (level) => {
    switch (level) {
      case AMATEUR:
        return (
          <img
            src={iconAmateur}
            alt={AMATEUR}
          />
        );
      case ENTHUSIAST:
        return (
          <img
            src={iconEnthusiast}
            alt={ENTHUSIAST}
          />
        );
      case PROFESSIONAL:
        return (
          <img
            src={iconProffesional}
            alt={PROFESSIONAL}
          />
        );
        case ELITE:
        return (
          <img
            src={iconElite}
            alt={ELITE}
          />
        );
      default:
        return (
          <img
            src={iconAmateur}
            alt={AMATEUR}
          />
        );
    }
  };

  return (
    userExperience && (
      <nav
        id="header__nav"
        data-e2e="nav"
        className={clsx(
          styles.nav,
          openedMenu ? styles.activeMenu : styles.hideMenu,
        )}
        ref={nav}
      >
        <div className={styles.user}>
          <div className={styles.profileBtn}>
            <h4>{name}</h4>

            {getIconForLevel(userExperience)}
          </div>

          <div className={styles.userInfo}>
            <p>
              {currentUser && currentUser.first_name
                ? `${currentUser.first_name} ${currentUser.last_name}`
                : ''}
            </p>

            <span className={styles.userRole}>
              {currentUser && currentUser.type ? currentUser.type : ''}
            </span>

            <Divider
              type="vertical"
              style={{ borderLeftColor: theme.colors.neutral300 }}
            />

            <span
              className={
                userExperience === AMATEUR
                  ? customStyle.userLevelAmateur
                  : userExperience === PROFESSIONAL
                  ? customStyle.userLevelProfessional
                  : customStyle.userLevelEnthusiast
              }
            >
              {getValueByCode(
                currentUserPrefs,
                APP_CONSTANS.PHOTOGRAPHER_PROPS.LEVEL.CODE,
              )}
            </span>

            <p>{currentUser && currentUser.email ? currentUser.email : ''}</p>
          </div>
        </div>

        {/* <Button className={styles.btnSwitch}>Switch to Customer</Button> */}

        <List
          showListForSuperuser={showListForSuperuser}
          setOpenedMenu={setOpenedMenu}
          showOnboardingButton={showOnboardingButton}
          setShowOnboarding={setShowOnboarding}
        />

        <div className={styles.copyright}>
          © {CURRENT_YEAR} FotoFetch, Inc. All rights reserved
        </div>
      </nav>
    )
  );
};
