import {
  CloseOutlined,
  MenuOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import { Badge, Button, Layout, Skeleton, message } from 'antd';
import { CheckIn } from 'components/CheckIn';
import { useCheckPoints } from 'components/Photographer-Homepage/hooks/useCheckPoints';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { APP_PATH } from '../../features/Routes/helpers';
import { APP_CONSTANS } from '../../shared/constants/index';
import iconProffesional from '../../shared/images/crown.svg';
import logo from '../../shared/images/logo.svg';
import iconAmateur from '../../shared/images/star.svg';
import iconEnthusiast from '../../shared/images/trophy.svg';
import iconElite from '../../shared/images/elite.svg';
import { signOut } from '../../store/auth/actions';
import { Comment } from '../Comment';
import { Onboarding } from '../Onboarding';
import { Navigation } from './Navigation/index';
import { AlertNewApp } from './AlertNewApp/index';
import styles from './styles.module.scss';

const AntHeader = Layout.Header;

export const Header = () => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [name, setName] = useState('');
  const [userExperience, setUserExperience] = useState('');
  const [showOnboardingButton, setShowOnboardingButton] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showCommentIcon, setShowCommentIcon] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [quantityOfNewComments, setQuantityOfNewComments] = useState(null);

  const user = useSelector((state) => state.user.user);
  const messageObject = useSelector((state) => state.messaging.message);
  const currentComments = useSelector((state) => state.jobs.currentComments);
  const currentUserPrefs = useSelector((state) => state.user.userPrefs);
  const jobStateId = useSelector((state) => state.jobs.currentJob?.state_id);

  const profileImage = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const iconSize = { fontSize: '24px' };
  const currentPath = location.pathname;
  const completedJobState =
    jobStateId === APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.COMPLETED_JOB;

  const { AMATEUR, ENTHUSIAST, PROFESSIONAL, ELITE} = APP_CONSTANS.PHOTOGRAPHER_PROPS.LEVEL;

  const {
    checkPointsLoading,
    activeCheckPoint,
    handleButtonClick: handleCheckButtonClick,
    modals,
    checkPoints,
  } = useCheckPoints();

  const checkInButtonText = useMemo(() => {
    if (activeCheckPoint) {
      return 'Check out';
    }
    if (checkPoints?.length > 0) {
      return 'Re-check in';
    }
    return 'Check in';
  }, [activeCheckPoint, checkPoints]);

  useEffect(() => {
    if (messageObject.content && messageObject.content.length) {
      messageObject.status === APP_CONSTANS.MESSAGING.STATUS.OK &&
        message.success(`${messageObject.content}`, 3);
      messageObject.status === APP_CONSTANS.MESSAGING.STATUS.ERROR &&
        message.error(`${messageObject.content}`, 3);
    }
  }, [messageObject.id, messageObject.status, messageObject.content]);

  useEffect(() => {
    if (currentUserPrefs && currentUserPrefs.length > 0) {
      const levels = currentUserPrefs.find(
        (i) => i.preference_id === APP_CONSTANS.PHOTOGRAPHER_PROPS.LEVEL.CODE,
      );
      const filteredLevel = levels.preference.options.find(
        (level) => level.value === levels.value,
      );
      setUserExperience(
        filteredLevel?.value
          ? filteredLevel.value
          : APP_CONSTANS.PHOTOGRAPHER_PROPS.LEVEL.AMATEUR,
      );
    }
  }, [currentUserPrefs]);

  useEffect(() => {
    if (currentComments && currentComments?.data?.length > 0) {
      const haveNewCommentsArray = currentComments?.data?.filter(comment => !comment.hasOwnProperty('exposed_at'));
      setQuantityOfNewComments(haveNewCommentsArray.length);
    } else {
      setQuantityOfNewComments(0);
    }
  }, [currentComments]);

  useEffect(() => {
    if (user && user?.error) {
      dispatch(signOut());
    }
    if (user && !user?.error && Object.entries(user).length > 0) {
      setName(`${user.first_name.substr(0, 1)}${user.last_name.substr(0, 1)}`);
    }
  }, [user, dispatch]);

  const openMenu = () => {
    openedMenu ? setOpenedMenu(false) : setOpenedMenu(true);
  };

  const closeHeaderMenu = () => {
    setOpenedMenu(false);
  };

  const showCheckInButton = useMemo(
    () => currentPath.includes('/fulfilment/') && !completedJobState,
    [completedJobState, currentPath],
  );

  useEffect(() => {
    currentPath.includes('/fulfilment/')
      ? setShowCommentIcon(true)
      : setShowCommentIcon(false);
    currentPath.includes('/profile')
      ? setShowOnboardingButton(true)
      : setShowOnboardingButton(false);
  }, [currentPath, completedJobState]);

  const getIconForLevel = (level) => {
    switch (level) {
      case AMATEUR:
        return (
          <img
            src={iconAmateur}
            alt={AMATEUR}
          />
        );
      case ENTHUSIAST:
        return (
          <img
            src={iconEnthusiast}
            alt={ENTHUSIAST}
          />
        );
      case PROFESSIONAL:
        return (
          <img
            src={iconProffesional}
            alt={PROFESSIONAL}
          />
        );
      case ELITE:
        return (
          <img
            src={iconElite}
            alt={ELITE}
          />
        );
      default:
        return (
          <img
            src={iconAmateur}
            alt={AMATEUR}
          />
        );
    }
  };

  const checkInButton = useMemo(() => {
    const {
      showCheckInWithoutLocationModal,
      showLocationEnableModal,
      showLongDistanceModal,
    } = modals;
    const modalShown =
      showCheckInWithoutLocationModal ||
      showLocationEnableModal ||
      showLongDistanceModal;
    const content =
      checkPointsLoading ? (
        <Skeleton.Button
          className={styles.checkInButtonSkeleton}
          active
          block
          shape="round"
        />
      ) : (
        <Button
          type="primary"
          ghost
          className={styles.checkInButton}
          onClick={handleCheckButtonClick}
          disabled={checkPointsLoading || modalShown}
          data-e2e="checkInOutBtn"
        >
          {checkInButtonText}
        </Button>
      );

    return <div className={styles.checkInButtonContainer}>{content}</div>;
  }, [checkInButtonText, checkPointsLoading, handleCheckButtonClick, modals]);

  return (
    userExperience && (
      <>
        <AlertNewApp />
        <AntHeader className={styles.header}>
          <div className={styles.headerWrapper}>
            <div>
              <Link to={APP_PATH.ROOT} onClick={closeHeaderMenu}>
                <img src={logo} alt="FOTOFETCH" />
              </Link>

              <div className={styles.block}>
                {/* <Button className={styles.notification}>
                              {notification.length > 0 && (
                                  <img className={styles.notificationBtn} src={hasNotification} alt="has-notification"/>
                              )}

                              {notification.length === 0 && (
                                  <BellOutlined style={iconSize}/>
                              )}
                          </Button> */}

                {showCheckInButton && checkInButton}

                {showCommentIcon && (
                  <Button
                    type="link"
                    className={styles.commentBtn}
                    onClick={() => setShowComments(true)}
                  >
                    <Badge
                      size="small"
                      className={styles.badgeIcon}
                      count={quantityOfNewComments}
                    >
                      <MessageOutlined />
                    </Badge>
                  </Button>
                )}

                {showOnboardingButton && (
                  <Button
                    type="primary"
                    className={styles.onboardingBtn}
                    onClick={() => setShowOnboarding(true)}
                  >
                    View UI Walkthrough
                  </Button>
                )}

                <div
                  data-e2e="tablet-menu"
                  className={styles.menu}
                  onClick={() => openMenu()}
                >
                  {openedMenu ? (
                    <CloseOutlined style={iconSize} />
                  ) : (
                    <MenuOutlined style={iconSize} />
                  )}
                </div>

                {!openedMenu && <div className={styles.hideMenu}></div>}

                <div className={styles.icon}>
                  <Button
                    className={styles.profileBtn}
                    onClick={() => openMenu()}
                    ref={profileImage}
                  >
                    <h4>{name}</h4>

                    {getIconForLevel(userExperience)}
                  </Button>
                </div>
              </div>
            </div>

            <Navigation
              showOnboardingButton={showOnboardingButton}
              setShowOnboarding={setShowOnboarding}
              openedMenu={openedMenu}
              profileImage={profileImage}
              name={name}
              setOpenedMenu={setOpenedMenu}
            />

            {showComments && (
              <Comment
                showComment={showComments}
                setShowComment={setShowComments}
              />
            )}

            {showOnboarding && (
              <Onboarding
                show={showOnboarding}
                close={setShowOnboarding}
                openedByUser={true}
              />
            )}

            <CheckIn
              modals={modals}
              handleCheckButtonClick={handleCheckButtonClick}
            />
          </div>
        </AntHeader>
      </>
    )
  );
};
